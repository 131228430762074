<template>
  <div class="relative -mt-16 flex h-auto lg:-mt-32">
    <div
      v-if="variant === 'main' && image"
      class="absolute left-0 top-0 h-full w-full lg:h-[75vh]"
    >
      <Image
        class="absolute right-0 top-0 h-full !w-3/4 object-cover object-center lg:object-left"
        v-bind="image?.data?.attributes"
      />
      <div
        class="from-black-500 absolute right-0 top-0 h-1/6 w-3/4 bg-gradient-to-b"
      ></div>
      <div
        class="from-black-950 absolute left-1/4 top-0 h-full w-1/2 bg-gradient-to-r"
      ></div>
      <div
        class="from-black-950 absolute bottom-0 right-0 h-1/2 w-full bg-gradient-to-t"
      ></div>
    </div>
    <div v-else-if="image?.data?.attributes">
      <Image
        class="absolute right-0 top-0 !h-full !w-full object-cover object-center"
        v-bind="image.data?.attributes"
      />
      <div
        class="absolute right-0 top-0 h-full w-full bg-gradient-to-l from-[#22C5A1BD] to-[#03AD87] mix-blend-multiply"
        :style="{
          '--tw-gradient-stops':
            'var(--tw-gradient-from)14.9%,var(--tw-gradient-to)46.28%',
        }"
      ></div>
    </div>
    <div
      class="container relative mx-auto pb-20 pt-32 lg:px-3.5 lg:pb-40 lg:pt-72"
    >
      <GridLayout :variant="gridVariant ?? 'guttersOnly'">
        <Icon
          v-if="assetrushLabel"
          class="mb-2 h-11 w-28 md:mb-3"
          name="AssetRushLabel"
        />
        <div class="col-span-full flex flex-col gap-10">
          <HeadlineGroup
            :class="[headlineClass, 'break-words text-white']"
            v-bind="headlineGroup"
            :date="date"
          />
          <slot></slot>
          <StrapiButton
            v-if="button"
            class="w-full justify-center sm:w-max sm:justify-start"
            v-bind="button"
          />
        </div>
      </GridLayout>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType, computed as vComputed } from "vue";
import { HeadlineProps } from "./types";
import { ImageType } from "~/components/Image/types";
import { Button } from "~/components/StrapiButton/types";

const props = defineProps({
  variant: {
    type: String as PropType<"main" | "event">,
    default: "main",
  },
  headlineGroup: {
    type: Object as PropType<HeadlineProps>,
    default: () => ({
      title: "Digital Assets",
      subtitle: "Start the revolution",
      description:
        "Our team consists of experienced and knowledgeable professionals who are passionate about what they do. We work tirelessly to provide you with innovative solutions that are tailored to your unique needs, and we are always available to answer any questions or concerns you may have.",
      variant: "primary",
    }),
  },
  image: {
    type: Object as PropType<ImageType>,
    default: () => {},
  },
  assetrushLabel: { type: Boolean, default: false },
  date: { type: String, default: "" },
  button: { type: Object as PropType<Button>, default: () => {} },
  gridVariant: { type: String, default: "guttersOnly" },
});
const variantClass = {
  main: "sm:w-3/4",
  event: "",
};

const headlineClass = vComputed(() => variantClass[props.variant]);
</script>
